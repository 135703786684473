.code-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
    opacity: 0.2;
  }
  
  .code-animation pre {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    white-space: pre-wrap;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    animation: type 10s steps(300, end) infinite;
  }
  
  .code-animation-container {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
    white-space: pre-wrap;
  }
  body {
    background-color: black;
  }
  .typewriter-text {
    color: #003b00 ; /* Replace this with the desired color */
  }
  
  @keyframes type {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  